<!-- eslint-disable vue/html-self-closing -->
<template>
  <div
    class="header"
    :style="{ position: $route.name === 'ArtPaint' ? 'absolute' : 'relative' }"
    :class="isArt ? 'art' : ''"
  >
    <div class="header-container">
      <router-link to="/">
        <img v-show="!isArt" src="@/assets/image/jiabaoli-logo.png" alt="" />
        <img
          v-show="isArt"
          src="@/assets/image/jiabaoli-logo-white.png"
          alt=""
        />
      </router-link>

      <ul class="menu" @mouseleave="handleMenuMouseleave">
        <li
          v-for="item in routes"
          :key="item.path"
          :class="[
            'menu-item',
            item.path === '/'
              ? currentRoutePath === item.path
                ? 'active'
                : ''
              : currentRoutePath.includes(item.path)
              ? 'active'
              : '',
          ]"
          @mouseenter="onPrimaryMouseEnter(item)"
        >
          <a
            v-if="
              ['/building', '/industry', '/international'].includes(item.path)
            "
          >
            {{ item.meta.title }}
          </a>
          <Link v-else :to="resolvePath(item.path)">
            <p @click.stop="onPrimaryClick(item.path)">{{ item.meta.title }}</p>
          </Link>

          <template v-if="excludePath(item.path)">
            <div v-if="showSubMenu === item.path" class="sub-menu">
              <div class="mask" @mouseenter="hideMenu" />
              <div class="sub-menu-content">
                <div class="left">
                  <div class="title">{{ item.meta.subTitle }}</div>
                  <template v-if="item.path === '/furnish'">
                    <ul class="sub-menu-list">
                      <li
                        v-for="sub in subMenuList"
                        :key="sub.path"
                        :class="[
                          'sub-menu-item',
                          furnishActive === sub.path ? 'active' : '',
                        ]"
                        @mouseover="onSubMenuMouseover(sub)"
                        @click="onSubMenuClick(sub)"
                      >
                        {{ sub.meta.title }}
                      </li>
                    </ul>
                  </template>
                </div>
                <!-- 三级菜单 -->
                <ul class="right" @click="hideMenu">
                  <!-- 关于我们 -->
                  <template v-if="item.path === '/about'">
                    <li
                      v-for="sub in subMenuList"
                      :key="sub.path"
                      :class="[
                        'right-item',
                        'right-about-item',
                        currentRoutePath.includes(sub.path) ? 'active' : '',
                      ]"
                    >
                      <Link :to="resolvePath(item.path, sub.path)">
                        <span>{{ sub.meta.title }}</span>
                      </Link>
                    </li>
                  </template>

                  <!-- 装修 -->
                  <template v-if="item.path === '/furnish'">
                    <!-- 产品 -->
                    <template v-if="furnishActive === 'product'">
                      <li
                        v-for="pro in productTypeList"
                        :key="pro.title"
                        :class="[
                          'right-item',
                          'right-product-item',
                          productActive === pro.index ? 'active' : '',
                        ]"
                      >
                        <Link :to="pro.path">
                          <span>{{ pro.title }}</span>
                        </Link>
                      </li>
                    </template>
                    <template v-if="furnishActive === 'serve'">
                      <li
                        :class="[
                          'right-item',
                          'right-serve-item',
                          currentRoutePath.includes('oldroom') ? 'active' : '',
                        ]"
                      >
                        <Link :to="'/furnish/serve/oldroom'">
                          <span>焕新家</span>
                        </Link>
                      </li>
                      <div class="serve-box">
                        <li
                          :class="[
                            'right-item',
                            'right-serve-item',
                            currentRoutePath.includes('authenticity')
                              ? 'active'
                              : '',
                          ]"
                        >
                          <Link :to="`/furnish/serve/authenticity`">
                            <span>真伪查询</span>
                          </Link>
                        </li>
                        <li
                          :class="[
                            'right-item',
                            'right-serve-item',
                            currentRoutePath.includes('certified')
                              ? 'active'
                              : '',
                          ]"
                        >
                          <Link :to="`/furnish/serve/certified`">
                            <span>认证查询</span>
                          </Link>
                        </li>
                      </div>
                    </template>
                    <template v-if="furnishActive === 'color-inspiration'">
                      <li
                        :class="[
                          'right-item',
                          'right-color-item',
                          activeColorInspiration === 1 ? 'active' : '',
                        ]"
                      >
                        <Link :to="'/furnish/color-inspiration/index#postion1'">
                          <span>VR色彩空间</span>
                        </Link>
                      </li>
                      <div class="color-box">
                        <li
                          :class="[
                            'right-item',
                            'right-color-item',
                            activeColorInspiration === 2 ? 'active' : '',
                          ]"
                        >
                          <Link
                            :to="'/furnish/color-inspiration/index#postion2'"
                          >
                            <span>色彩探索</span>
                          </Link>
                        </li>
                        <li
                          :class="[
                            'right-item',
                            'right-color-item',
                            activeColorInspiration === 3 ? 'active' : '',
                          ]"
                        >
                          <Link
                            :to="'/furnish/color-inspiration/index#postion3'"
                          >
                            <span>生活灵感</span>
                          </Link>
                        </li>
                      </div>
                    </template>

                    <template v-if="furnishActive === 'cooperation'">
                      <li :class="['right-item', 'right-cooperation-item']">
                        <Link :to="`/about/zsjm`">
                          <span>招商合作</span>
                        </Link>
                      </li>
                      <li :class="['right-item', 'right-cooperation-item']">
                        <Link :to="`/about/introduce`">
                          <span>关于嘉宝莉</span>
                        </Link>
                      </li>
                    </template>
                  </template>

                  <!-- 建筑 -->
                  <template v-if="item.path === '/building'">
                    <li
                      v-for="sub in subMenuList"
                      :key="sub.path"
                      :class="[
                        'right-item',
                        'right-building-item',
                        currentRoutePath.includes(sub.path) ? 'active' : '',
                      ]"
                    >
                      <Link :to="resolvePath(item.path, sub.path)">
                        <span>{{ sub.meta.title }}</span>
                      </Link>
                    </li>
                  </template>
                  <!-- 家具涂料 样式比较特殊 -->
                  <template v-if="item.path === '/industry'">
                    <li :class="['right-item', 'right-industry-item']">
                      <Link :to="'https://wwwunit.carpoly.com/furniture'">
                        <span>家具涂料</span>
                      </Link>
                    </li>
                    <div class="industry-box">
                      <li
                        :class="[
                          'right-item',
                          'right-industry-item',
                          currentRoutePath.includes('industrial-coatings')
                            ? 'active'
                            : '',
                        ]"
                      >
                        <Link :to="`/industry/industrial-coatings`">
                          <span>工业涂料</span>
                        </Link>
                      </li>
                      <li
                        :class="[
                          'right-item',
                          'right-industry-item',
                          currentRoutePath.includes('ink') ? 'active' : '',
                        ]"
                      >
                        <Link :to="`/industry/ink`">
                          <span>油墨</span>
                        </Link>
                      </li>
                    </div>
                  </template>
                  <!-- 国际 -->
                  <template v-if="item.path === '/international'">
                    <li
                      v-for="sub in subMenuList"
                      :key="sub.path"
                      :class="[
                        'right-item',
                        'right-international-item',
                        currentRoutePath.includes(sub.path) ? 'active' : '',
                      ]"
                    >
                      <Link :to="resolvePath(item.path, sub.path)">
                        <span>{{ sub.meta.title }}</span>
                      </Link>
                    </li>
                  </template>
                </ul>
              </div>
            </div>
          </template>
        </li>
      </ul>
    </div>
    <img
      v-show="!isArt"
      class="icon"
      src="@/assets/image/menu/search-icon.png"
      @click="goSearch"
    />
    <img
      v-show="isArt"
      class="icon"
      src="@/assets/image/menu/search-icon-white.png"
      @click="goSearch"
    />
  </div>
</template>

<script>
import path from 'path';
import Link from './Link';

import { isExternal } from '@/utils';

export default {
  name: 'HeaderIndex',
  components: {
    Link,
  },

  data() {
    return {
      isArt: false,

      currentRoutePath: '',
      showSubMenu: '',
      subMenuList: [],
      furnishActive: '', // 判断装修三级菜单类型
      threeMenuList: [],
      productTypeList: [
        {
          title: '艺术涂料',
          path: '/furnish/art-paint',
          index: 2,
        },
        {
          title: '内墙涂料',
          path: '/furnish/product/list?type=3',
          index: 3,
        },
        {
          title: '石艺漆',
          path: '/furnish/stone-art-paint',
          index: 4,
        },
        {
          title: '木器漆',
          path: '/furnish/product/list?type=5',
          index: 5,
        },
        {
          title: '防水产品',
          path: '/furnish/product/list?type=6',
          index: 6,
        },
        {
          title: '辅材\\美缝',
          path: '/furnish/product/list?type=7',
          index: 7,
        },
        {
          title: '万宜漆',
          path: '/furnish/wangee',
          index: 8,
        },
      ],
      productActive: 0,
      activeColorInspiration: 0,
    }
  },

  computed: {
    routes() {
      return this.$router.options.routes.filter((item) => !item.hidden)
    },
  },
  watch: {
    $route: {
      handler(newValue) {
        const { path, name } = newValue
        this.currentRoutePath = path
        this.isArt = name === 'ArtPaint' ? true : false
        path.includes('/furnish') && this.activeSubMenu()
      },
      immediate: true,
    },
  },

  methods: {
    resolvePath(parentPath = '', routePath = '') {
      if (isExternal(routePath)) {
        return routePath
      }
      if (isExternal(parentPath)) {
        return parentPath
      }
      return path.resolve(parentPath, routePath)
    },

    excludePath(routePath) {
      return routePath !== '/' && !isExternal(routePath)
    },

    activeSubMenu() {
      if (this.$route.path.includes('serve')) {
        this.furnishActive = 'serve'
      } else if (this.$route.path.includes('color-inspiration')) {
        this.furnishActive = 'color-inspiration'
        this.activeColorInspiration =
          this.$route.hash === '#postion1'
            ? 1
            : this.$route.hash === '#postion2'
            ? 2
            : this.$route.hash === '#postion3'
            ? 3
            : 0
      } else if (this.$route.path.includes('cooperation')) {
        this.furnishActive = 'cooperation'
      } else {
        this.furnishActive = 'product'
        if (this.$route.path === '/furnish/art-paint') {
          this.productActive = 2
        } else if (this.$route.path === '/furnish/stone-art-paint') {
          this.productActive = 4
        } else if (this.$route.path.includes('/furnish/wangee')) {
          this.productActive = 8
        } else {
          this.productActive = Number(this.$route.query.type)
        }
      }
    },

    handleMenuMouseleave() {
      this.$route.name === 'ArtPaint' && (this.isArt = true)
    },

    onPrimaryMouseEnter(item) {
      this.showSubMenu = item.path
      this.subMenuList = item.children?.filter((item) => !item.hidden)
      this.$route.name === 'ArtPaint' && (this.isArt = false)
      item.path === '/furnish' && this.activeSubMenu()
    },

    onPrimaryClick(path) {
      if (path === '/furnish') {
        this.showSubMenu = ''
      }
    },

    hideMenu() {
      this.$route.name === 'ArtPaint' && (this.isArt = true)
      this.showSubMenu = ''
    },

    onSubMenuClick(item) {
      if (item.path === 'wangee') {
        this.$router.push('/furnish/wangee')
        this.showSubMenu = ''
      }
    },

    // 装修二级菜单点击事件监听
    onSubMenuMouseover(item) {
      this.furnishActive = item.path
      this.threeMenuList = item.children?.filter((item) => !item.hidden)

      if (
        this.$route.path === '/furnish/art-paint' ||
        this.$route.path === '/furnish/stone-art-paint' ||
        this.$route.path.includes('product')
      ) {
        if (this.$route.path === '/furnish/art-paint') {
          this.productActive = 2
        } else if (this.$route.path === '/furnish/stone-art-paint') {
          this.productActive = 4
        } else {
          this.productActive = Number(this.$route.query.type)
        }
      }

      this.activeColorInspiration = this.$route.path.includes(
        'color-inspiration'
      )
        ? this.$route.hash === '#postion1'
          ? 1
          : this.$route.hash === '#postion2'
          ? 2
          : this.$route.hash === '#postion3'
          ? 3
          : 0
        : 0
    },

    goSearch() {
      this.$router.push('/search')
    },
  },
}
</script>

<style lang="scss" scoped>
.header {
  width: 100%;
  height: 79px;
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.1);
  background: #ffffff;
  z-index: 100000;
  .header-container {
    width: 1080px;
    height: 100%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .menu {
      width: 769px;
      height: 100%;
      display: flex;
      justify-content: space-between;
      .menu-item {
        & > a {
          width: 100%;
          display: block;
          padding: 0 12px;
          line-height: 79px;
          position: relative;
        }
        & > a:after {
          content: '';
          display: block;
          position: absolute;
          top: 0;
          left: 0;
          height: 10px;
          width: 100%;
          background-color: #e73c3e;
          transform: scaleX(0);
          transition: transform 0.25s ease-in-out;
        }
      }
      .menu-item:hover a:after {
        transform: scaleX(1);
      }
      .menu-item.active a::after {
        transform: scaleX(1);
      }
    }
  }
  .mask {
    position: fixed;
    width: 100%;
    height: calc(100vh - 79px);
    background: rgba(0, 0, 0, 0.7);
    z-index: 100;
  }

  .sub-menu {
    width: 100%;
    position: absolute;
    left: 0;
    background-color: #fff;
    .sub-menu-content {
      position: relative;
      z-index: 1000;
      background: #fff;
      min-height: 320px;
      padding: 35px 0 39px;
      display: flex;
      justify-content: center;
      & > .left {
        width: 173px;
        margin-right: 52px;
        & > .title {
          font-size: 32px;
          font-weight: 400;
          color: #323232;
        }

        & > .sub-menu-list {
          padding-top: 18px;
          .sub-menu-item {
            width: 173px;
            height: 39px;
            display: block;
            position: relative;
            text-indent: 35px;
            line-height: 39px;
          }
          .sub-menu-item:after {
            content: '';
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 5px;
            background: linear-gradient(90deg, #031eb7, #1847bc, #3055bd);
            transform: scaleY(0);
            transition: transform 0.25s ease-in-out;
          }
          .sub-menu-item:hover:after {
            transform: scaleY(1);
          }
          .sub-menu-item.active {
            background-color: #f5f5f5;
          }
          .sub-menu-item.active:after {
            transform: scaleY(1);
          }
        }
      }
      & > .right {
        width: 585px;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        & > .right-item,
        & > .serve-box .right-item,
        & > .color-box .right-item,
        & > .industry-box .right-item {
          background-size: 100% 100%;
          background-repeat: no-repeat;
          margin-bottom: 11px;
          & > a {
            display: block;
            width: 100%;
            height: 100%;
            position: relative;
            & > span {
              display: block;
              width: 100%;
              font-size: 16px;
              font-weight: 400;
              color: #323232;
              line-height: 36px;
              text-align: center;
              position: absolute;
              bottom: 0px;
              background: #ffffff;
              opacity: 0.8;
              cursor: pointer;
            }
          }
        }
        & > .right-item:hover > a > span,
        & > .serve-box .right-item:hover > a > span,
        & > .color-box .right-item:hover > a > span,
        & > .industry-box .right-item:hover > a > span {
          color: #ffffff;
          background: linear-gradient(90deg, #031eb7, #1847bc, #3055bd);
        }

        & > .right-item.active > a > span,
        & > .serve-box .right-item.active > a > span,
        & > .color-box .right-item.active > a > span,
        & > .industry-box .right-item.active > a > span {
          color: #ffffff;
          background: linear-gradient(90deg, #031eb7, #1847bc, #3055bd);
        }

        & > .right-about-item {
          width: 286px;
          height: 159px;
        }

        & > .right-about-item:nth-child(1) {
          background-image: url('@/assets/image/menu/about1.png');
        }
        & > .right-about-item:nth-child(2) {
          background-image: url('@/assets/image/menu/about2.png');
        }
        & > .right-about-item:nth-child(3) {
          background-image: url('@/assets/image/menu/about3.png');
        }
        & > .right-about-item:nth-child(4) {
          background-image: url('@/assets/image/menu/about4.jpg');
        }
        & > .right-about-item:nth-child(5) {
          background-image: url('@/assets/image/menu/about5.jpg');
        }
        & > .right-about-item:nth-child(6) {
          background-image: url('@/assets/image/menu/about6.jpg');
        }

        & > .right-product-item:nth-child(1) {
          width: 286px;
          height: 159px;
          background-image: url('@/assets/image/menu/product1.png');
        }
        & > .right-product-item:nth-child(2) {
          width: 286px;
          height: 159px;
          background-image: url('@/assets/image/menu/product2.png');
        }

        & > .right-product-item:nth-child(3) {
          width: 286px;
          height: 159px;
          background-image: url('@/assets/image/menu/product3.png');
        }
        & > .right-product-item:nth-child(4) {
          width: 286px;
          height: 159px;
          background-image: url('@/assets/image/menu/product4.png');
        }
        & > .right-product-item:nth-child(5) {
          width: 188px;
          height: 159px;
          background-image: url('@/assets/image/menu/product5.png');
        }
        & > .right-product-item:nth-child(6) {
          width: 188px;
          height: 159px;
          background-image: url('@/assets/image/menu/product6.png');
        }
        & > .right-product-item:nth-child(7) {
          width: 188px;
          height: 159px;
          background-image: url('@/assets/image/menu/product7.png');
        }

        & > .right-serve-item {
          width: 287px;
          height: 329px;
          background-image: url('@/assets/image/menu/serve1.png');
        }
        & > .serve-box {
          display: flex;
          flex-direction: column;
          margin-left: 11px;
          & > .right-serve-item:nth-child(1) {
            width: 287px;
            height: 159px;
            background-image: url('@/assets/image/menu/serve2.png');
            margin-bottom: 11px;
          }

          & > .right-serve-item:nth-child(2) {
            width: 287px;
            height: 159px;
            background-image: url('@/assets/image/menu/serve3.png');
          }
        }

        & > .right-color-item {
          width: 585px;
          height: 235px;
          background-image: url('@/assets/image/menu/color1.png');
        }

        & > .color-box {
          display: flex;
          margin-top: 16px;
          & > .right-color-item:nth-child(1) {
            width: 286px;
            height: 249px;
            background-image: url('@/assets/image/menu/color2.png');
            margin-right: 13px;
          }

          & > .right-color-item:nth-child(2) {
            width: 286px;
            height: 249px;
            background-image: url('@/assets/image/menu/color3.png');
          }
        }

        & > .right-cooperation-item {
          width: 287px;
          height: 329px;
        }
        & > .right-cooperation-item:nth-child(1) {
          background-image: url('@/assets/image/menu/cooperation1.png');
        }
        & > .right-cooperation-item:nth-child(2) {
          background-image: url('@/assets/image/menu/cooperation2.png');
        }

        & > .right-specialty-store-item {
          width: 585px;
          height: 329px;
          background-image: url('@/assets/image/menu/specialty-store.png');
        }

        & > .right-sub-brand-item {
          width: 287px;
          height: 329px;
        }

        & > .right-sub-brand-item:nth-child(1) {
          background-image: url('@/assets/image/menu/sub-brand1.png');
        }
        & > .right-sub-brand-item:nth-child(2) {
          background-image: url('@/assets/image/menu/sub-brand2.png');
        }

        & > .right-building-item {
          width: 286px;
          height: 159px;
        }
        & > .right-building-item:nth-child(1) {
          background-image: url('@/assets/image/menu/building1.png');
        }
        & > .right-building-item:nth-child(2) {
          background-image: url('@/assets/image/menu/building4.png');
        }
        & > .right-building-item:nth-child(3) {
          background-image: url('@/assets/image/menu/building3.png');
        }
        & > .right-building-item:nth-child(4) {
          background-image: url('@/assets/image/menu/building2.png');
        }

        & > .right-industry-item:nth-child(1) {
          width: 287px;
          height: 329px;
          background-image: url('@/assets/image/menu/industry1.png');
        }

        & > .industry-box {
          width: 286px;
          & > .right-industry-item:nth-child(1) {
            width: 100%;
            height: 159px;
            background-image: url('@/assets/image/menu/industry2.png');
            margin-bottom: 11px;
          }
          & > .right-industry-item:nth-child(2) {
            width: 100%;
            height: 159px;
            background-image: url('@/assets/image/menu/industry3.png');
          }
        }

        & > .right-international-item {
          width: 288px;
          height: 330px;
        }
        & > .right-international-item:nth-child(1) {
          background-image: url('@/assets/image/menu/international1.png');
        }
        & > .right-international-item:nth-child(2) {
          background-image: url('@/assets/image/menu/international2.png');
        }
      }
    }
  }
  & > .icon {
    width: 24px;
    height: 24px;
    position: absolute;
    right: 76px;
    top: 29px;
    cursor: pointer;
  }
}

.art {
  background: none;
  box-shadow: none;
  .menu {
    .menu-item {
      & > a {
        color: #ffffff;
      }
      & > a:after {
        background-color: #ffffff !important;
      }
    }
  }
}
</style>
